import { useDelete, useGet, usePost, usePut } from '#/api/reactQuery';
import queryClient from '#/api/query-client';

const baseURL = '/register-of-assets';

export const RegisterOfAssetsEndpoints = {
  getAssets: () => `${baseURL}/asset/list/`,
  addAsset: () => `${baseURL}/asset/add/`,
  exportAssets: () => `${baseURL}/asset/export/`,
  updateAsset: (id: number) => `${baseURL}/asset/update/${id}/`,
  deleteAsset: (id: number) => `${baseURL}/asset/delete/${id}/`,
  getFinancial: () => `${baseURL}/financial/list/`,
  exportFinancial: () => `${baseURL}/financial/export/`,
  exportAllData: () => `${baseURL}/assets-financial/export/`,
  financialIns: () => `${baseURL}/financial/add/`,
  financialUpdate: (id: number) => `${baseURL}/financial/update/${id}/`,
  financialDelete: (id: number) => `${baseURL}/financial/delete/${id}/`,
  commonBeneficiariesList: () => `${baseURL}/asset/common/list`,
};

export const invalidateRegisterOfAssets = {
  getAssets: () => {
    queryClient
      .invalidateQueries(RegisterOfAssetsEndpoints.getAssets())
      .then((r) => r);
  },
  getFinancial: () => {
    queryClient
      .invalidateQueries(RegisterOfAssetsEndpoints.getFinancial())
      .then((r) => r);
  },
  commonBeneficiariesList: () => {
    queryClient
      .invalidateQueries(RegisterOfAssetsEndpoints.commonBeneficiariesList())
      .then((r) => r);
  },
  exportAllData: () => {
    queryClient.removeQueries(RegisterOfAssetsEndpoints.exportAllData());
  },
};

type CreateAsset = {
  description: string;
  storage_location: string;
  beneficiary_id: number;
};

type Asset = {
  id: number | string;
  description: string;
  storage_location: string;
  beneficiary_id: number;
};

type Assets = {
  count: number;
  next: string;
  previous: string;
  results: Asset[];
};

type CreateFinancial = {
  institution_name: string;
  institution_type: string;
};

type Financial = {
  id: number | string;
  institution_name: string;
  institution_type: string;
};

type Financials = {
  count: number;
  next: string;
  previous: string;
  results: Financial[];
};

export const useGetAssets = (
  page: number,
  search?: string | undefined,
  order_by?: string | undefined,
  invitation_id?: number | string | undefined
) =>
  useGet<Assets>(RegisterOfAssetsEndpoints.getAssets(), {
    page,
    search,
    order_by,
    invitation_id,
  });

export const useExportAssets = () =>
  useGet<{ file_url: string }>(RegisterOfAssetsEndpoints.exportAssets());

export const useAddAsset = () =>
  usePost<CreateAsset, CreateAsset>(RegisterOfAssetsEndpoints.addAsset());

export const useUpdateAsset = (id: number) =>
  usePut<CreateAsset, CreateAsset>(RegisterOfAssetsEndpoints.updateAsset(id));

export const useDeleteAsset = (id: number) =>
  useDelete(RegisterOfAssetsEndpoints.deleteAsset(id));

export const useGetFinancial = (
  page: number,
  search?: string | undefined,
  order_by?: string | undefined,
  invitation_id?: number | string | undefined
) =>
  useGet<Financials>(RegisterOfAssetsEndpoints.getFinancial(), {
    page,
    search,
    order_by,
    invitation_id,
  });

export const useExportFinancial = () =>
  useGet<{ file_url: string }>(RegisterOfAssetsEndpoints.exportFinancial());

export const useAddFinancial = () =>
  usePost<CreateFinancial, CreateFinancial>(
    RegisterOfAssetsEndpoints.financialIns()
  );

export const useUpdateFinancial = (id: number) =>
  usePut<CreateFinancial, CreateFinancial>(
    RegisterOfAssetsEndpoints.financialUpdate(id)
  );

export const useDeleteFinancial = (id: number) =>
  useDelete(RegisterOfAssetsEndpoints.financialDelete(id));

export type List = {
  id: number | string;
  name: string;
  type: string;
};

export type CommonList = {
  beneficiaries: List[];
  representatives: List[];
};

export const useCommonBeneficiariesList = () =>
  useGet<CommonList>(RegisterOfAssetsEndpoints.commonBeneficiariesList());

export const useExportAllData = (
  enabled: boolean,
  invitation_id?: number | string | undefined
) =>
  useGet<{ file_url: string }>(
    RegisterOfAssetsEndpoints.exportAllData(),
    {
      invitation_id,
    },
    {
      enabled,
    }
  );
